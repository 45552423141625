@font-face {
font-family: '__matter_ee34e8';
src: url(/_next/static/media/1865298acede3ba8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__matter_ee34e8';
src: url(/_next/static/media/7e4f0097f7201fe9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__matter_ee34e8';
src: url(/_next/static/media/c9c442a0db8ba337-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__matter_ee34e8';
src: url(/_next/static/media/e092e86a5c91acb2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__matter_Fallback_ee34e8';src: local("Arial");ascent-override: 87.97%;descent-override: 18.49%;line-gap-override: 0.00%;size-adjust: 102.76%
}.__className_ee34e8 {font-family: '__matter_ee34e8', '__matter_Fallback_ee34e8'
}

